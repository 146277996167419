export const metaData = {
  metaTitle: 'Our finance API products',
  metaDescription:
    "From accessing detailed transaction history to account verification, Plaid's suite of APIs enables companies to easily build great financial products.",
};

export const pageHeaderData = {
  'content-width-classes': 'page-header-breakpoint-6 large-5',
  header: 'Our products',
  'header-class': 'h1',
  subheader:
    'Our suite of APIs enables developers to easily build great financial products.',
  'primary-cta': true,
  'primary-cta-text': 'Get started',
  'primary-cta-link': '//dashboard.plaid.com/signup',
  'secondary-cta': true,
  'secondary-cta-text': 'Visit docs',
  'secondary-cta-link': '/docs',
  video: true,
  'video-width': 1138.26,
  'video-height': 528,
  'video-fallback-image': '/assets/img/hero/products-dreambig-hero.png',
  'video-formats': [
    {
      url: '/assets/video/products-dreambig-hero.mp4',
      type: 'mp4',
    },
    {
      url: '/assets/video/products-dreambig-hero.webm',
      type: 'webm',
    },
  ],
};

export const productFeaturesData = [
  {
    name: 'Transactions',
    url: '/en-gb/products/transactions',
    description: '5+ years of categorised transaction data',
  },
  {
    name: 'Balance',
    url: '/en-gb/products/balance',
    description: 'Real-time balance checks',
  },
  {
    name: 'Assets',
    url: '/en-gb/products/assets',
    description: "Point-in-time snapshots of users' finances",
  },
  {
    name: 'Identity',
    url: '/en-gb/products/identity',
    description: 'Bank account-holder information',
  },
  {
    name: 'Auth',
    url: '/en-gb/products/auth',
    description: 'Account number, sort code, IBAN, and BIC',
  },
  {
    name: 'Plaid Link',
    url: '/en-gb/plaid-link',
    description:
      'Enable your users to instantly connect their financial accounts',
  },
];
