export const metaData = {
  'meta-title': 'Banking and wealth - financial data solutions',
  'meta-description':
    'Make it easy for people to interact with their bank accounts and investments.',
};

export const pageHeaderData = {
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  label: true,
  'label-text': 'Banking and wealth',
  header:
    'Make it easy for people to interact with their bank accounts and investments',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '#contactForm',
  'secondary-cta': false,
  'secondary-cta-text': null,
  'tertiary-cta':
    'Or, <a class="signup-attach-utm" href="//dashboard.plaid.com/signup">get started</a>',
  video: true,
  'video-width': 1138.26,
  'video-height': 528,
  'video-poster-image': '/assets/img/hero/banking-and-brokerage_hero.png',
  'video-fallback-image':
    '/assets/img/hero/banking-and-brokerage_hero-fallback.png',
  'video-formats': [
    {
      url: '/assets/video/banking-and-brokerage_hero.webm',
      type: 'webm',
    },
    {
      url: '/assets/video/banking-and-brokerage_hero.mp4',
      type: 'mp4',
    },
  ],
};

export const socialProofData = {
  header: 'Customers using this solution',
  alignment: 'center',
  'column-count': 4,
  'full-width': true,
  logos: [
    {
      img: '/assets/img/logos/logo-acorns-black600.png',
      alt: 'Acorns logo',
    },
    {
      img: '/assets/img/logos/logo-betterment-black600.png',
      alt: 'Betterment logo',
    },
    {
      img: '/assets/img/logos/logo-gemini-black600.png',
      alt: 'Gemini logo',
    },
    {
      img: '/assets/img/logos/logo-chime-black600.png',
      alt: 'Chime logo',
    },
  ],
};

export const introSectionData = {
  'problem-statement':
    "Obtaining a comprehensive picture of users' financial accounts is difficult when users have accounts across multiple financial institutions.",
  'solution-statement':
    'Plaid uses Open Banking integrations to aggregate data from credit card and bank accounts, and can also retrieve investment account data. ',
};

export const featureSectionData = {
  'feature-section-title': [
    {
      label: 'Products and benefits',
    },
  ],
  'feature-section-items': [
    {
      img: '/assets/img/products/intl-transactions-112@2x.png',
      title: "Generate a holistic view of your users' finances",
      'header-class': 'h5',
      content:
        '<a href="/uk/products/transactions">Transactions</a> analyses 5+ years of clean, categorised transaction history',
      'no-link': true,
    },
    {
      img: '/assets/img/products/investments-112.png',
      title: "Understand a user's investments",
      'header-class': 'h5',
      content:
        'Leverage <a href="/uk/products/investments">Investments</a> to gain insight into a user\'s investment accounts, including account balances, holdings, and transactions that create a change in a holding',
      'no-link': true,
      'link-text': 'Learn more',
      'link-location': '/products/investments',
    },
  ],
};

export const simpleCarouselData = {
  'carousel-title': 'How It Works',
  'carousel-slides': [
    {
      header: 'Integrate with Plaid',
      description:
        'It takes just a few lines of code to drop our front-end module into your app or digital service. Your users will be prompted to connect their bank account, which will launch the Plaid module.',
      'bg-color': 'bg-green',
      img: '/assets/img/carousel/use-cases-carousel-0.png',
    },
    {
      header: 'Customise for conversion',
      description:
        'Customise the module to fit your brand or user preferences, including which financial institutions show up first.',
      'bg-color': 'bg-blue',
      img: '/assets/img/carousel/use-cases-carousel-link-demo-1.png',
    },
    {
      header: 'Get user-permissioned bank data',
      description:
        'Once a user has gone through the flow, we do the work and return a range of relevant bank account data depending on what fits your needs.',
      'bg-color': 'bg-pink',
      img: '/assets/img/carousel/use-cases-carousel-link-demo-2.png',
    },
  ],
};

export const ctaData = {
  'marketo-key': 2007,
  title: 'Questions about how Plaid can fit into your business?',
  'with-subtext': true,
  subtext: 'Tell us how we can help and we’ll get in touch shortly.',
  'subtext-class': 'h4 regular',
  'include-phone': true,
};
